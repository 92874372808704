<template>
    <div class="view-services">
        <viewWrapper>
            <template #header>
                <textNav @click="$router.push('/visits')">Wróć do listy wolnych terminów</textNav>
            </template>
            <template #content>
                <splitedView>
                    <template #left>
                        <visitSummary />
                    </template>
                    <template #right>
                        <serviceList :items="services" @select="selected = $event"/>
                        <div class="services-footer">
                            <btn type="next" :disabled="selected ? false : true" @click="selectService()">kontynuuj</btn>
                        </div>
                    </template>
                </splitedView>
            </template>
        </viewWrapper>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import viewWrapper from '@/components/layout/viewWrapper/viewWrapper.vue';
import splitedView from '@/components/layout/splitedView/splitedView.vue';
import visitSummary from '@/components/functional/visitSummary/visitSummary.vue';
import serviceList from '@/components/functional/serviceList/serviceList.vue';

export default {

    name: 'Services',
    components: {
        viewWrapper,
        splitedView,
        visitSummary,
        serviceList,
    },
    data(){
        return {

            services: [],
            selected: null,

        };
    },
    methods: {

        ...mapActions('global', ['setSelectedService']),

        getServices(){

            return new Promise(resolve => {

                this.app__getToken().then(token => {

                    this.$http.get('visits/get_private_services_for_term/', {
                        params: {
                            selected_facility_token: token.accessToken,
                            remote_app: token.remoteApp,
                            date: this.selectedTerm.newVisit.date,
                            time: this.selectedTerm.newVisit.timeFrom,
                            doctor_id: this.selectedTerm.newVisit.doctor,
                            department_id: this.selectedTerm.newVisit.department,
                            evisit: this.selectedTerm.evisit,
                        },
                    }).then(response => {

                        this.services = response.data.term_private_services;

                        if (this.services.length === 0) this.manageRoute();

                        this.services.forEach((vt, index) => {
                            this.services[index].price = parseFloat(vt.price).toFixed(2).replace('.', ',');
                            this.services[index].numberPrice = parseFloat(vt.price).toFixed(2);
                        });

                        resolve();

                    }).catch(error => {
                        this.app__handleHttpFailureResponse(error);
                        resolve();
                    });

                });

            });

        },

        selectService(){

            this.setSelectedService(this.selected);
            this.manageRoute();

            return true;

        },

        manageRoute(){
            this.$router.push('/informations');
        },

    },
    computed: {

        ...mapState('user', ['user', 'selectedProfile']),
        ...mapState('global', ['remoteAppData', 'selectedTerm']),

    },
    created(){
        this.getServices();
        this.setSelectedService(null);
    },

};

</script>

<style lang="scss" src="./Services.scss" />
