<template>
    <div class="func-service-list">
        <div class="func-service-list__header">
            <img src="~@/assets/icons/stethoscope-ico.svg" />
            <h2>Wybierz usługę prywatną</h2>
        </div>
        <div class="func-service-list__subheader">
          <h3 v-if="!isPaymentRequired">Przedpłata nie jest wymagana</h3>
        </div>
        <div class="func-service-list__content">
            <div class="scroll-container">
                <div v-for="(item, index) in items" :key="index" class="list-item">
                    <div @click="selectItem(item)" class="list-item__name"
                         :class="{'list-item__name--active': checkSelected(item.id)}">
                        <p>{{item.name}}</p>
                    </div>
                    <div class="list-item__price">
                        <priceLabel @click="selectItem(item)" :active="checkSelected(item.id)">{{item.price}} zł</priceLabel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import priceLabel from '@/components/ui/priceLabel/priceLabel.vue';
import { mapState } from "vuex";

export default {

    name: 'serviceList',
    components: {
        priceLabel,
    },
    props: {

        items: {
            type: Array,
            default: () => [],
        },

    },
    data(){
        return {

            selected: null,

        };
    },
    computed: {
        ...mapState('global', ['selectedTerm']),

        isPaymentRequired() {
            return this.selectedTerm.prePayment;
        },

    },
    methods: {

        selectItem(item){

            this.selected = item;
            this.$emit('select', this.selected);

        },

        checkSelected(id){

            return this.selected?.id === id;

        },

    },

};

</script>

<style lang="scss" src="./serviceList.scss" />
